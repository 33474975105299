import Ch from "./Ch";
import En from "./En";

export default class Language{
    constructor(){
        var storageLanguage = localStorage.getItem("storageLanguage");
        if(!storageLanguage){
            storageLanguage = "en";
            localStorage.setItem("storageLanguage",storageLanguage);
        }
        this.currentLanguage = storageLanguage;
        this.langObject = {
            ch:new Ch(),
            en:new En(),
        }
    }

    toggleLanguage(){
        this.currentLanguage = this.currentLanguage == "ch" ? "en" : "ch";
        localStorage.setItem("storageLanguage",this.currentLanguage);
    }
    
    get(key){
        switch(this.currentLanguage){
            case "ch":
                return this.langObject.ch.get(key);
            case "en":
                return this.langObject.en.get(key);
        }
    }
}