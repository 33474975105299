import Base from "./Base";
export default class En extends Base{
    init(){
        this.lang={
            refresh:"Refresh Winners",
            waiting:"waiting",
            e_ticket:"E-ticket",
            participant:"Participant",
			participant_must_be_required:"Participant Must Be Required",
			participant_can_only_be_characters:"Participant can only be in 2-40 characters",
			please_fill_in_the_name:"Please fill in the name",
			please_fill_in_the_phone_no:"Please fill in the phone no",
			phone_no_must_be_required:"Phone No Must Be Required",
            phone_no:"Phone No",
            exit_login:"Exit Login",
            order_no:"Order No",
			order_time:"Order Time",
            cancel:"Cancel",
            determine:"Determine",
            lucky_draw_no:"Lucky Draw No.",
			bullet_screen_message:"Bullet Screen Message",
			leave_comments:"Leave Comments",
			program_voting:"Program Voting",
            program_voting_vote:"vote_en.png",
			program_voting_voted:"voted_en.png",
			total_of:"Total of",
			votes:"votes",
            activity_details:"Activity Details",
            send:"Send",
            participant:"Participant",
			participant_must_be_required:"Participant Must Be Required",
			participant_can_only_be_characters:"Participant can only be in 2-12 characters",
			please_fill_in_the_name:"Please fill in the name",
			please_fill_in_the_phone_no:"Please fill in the phone no",
			phone_no_must_be_required:"Phone No Must Be Required",
            phoneNumberError:"The cellular number is incorrect",
            pleaseLogInAgain:"Please login again",
            Failedtogetuserinfor:"Failed to get user information. Please try again later",
            beenExhausted:"Your voting opportunities have been exhausted",
            remainingVotes:"remaining votes",
            length100:"Text length cannot exceed 100",
            joinIn:"Join In",
            networdError:"Network error, please try again later",
            votingIsOver:"Voting is over",
            votingHasEnded:"This round of voting has ended. Please re-enter the next round of voting",
	        Live_Photos:"Live Photos",
            voting_is_pending:"Voting is Pending",
            voting_concluded:"Voting Concluded",
	}; 
    }
}
