import Base from "./Base";
export default class Ch extends Base{
    init(){
        this.lang={
            refresh:"刷新中奖",
            waiting:"等待",
            e_ticket:"电子票",
            participant:"参与人",
            participant_must_be_required:"参与人必须填写",
			participant_can_only_be_characters:"参与人必须是2-12长度内",
			please_fill_in_the_name:"请输入参与人姓名",
			please_fill_in_the_phone_no:"请输入参与活动电话号码",
			phone_no_must_be_required:"参与人必须填写",
            phone_no:"手机号",
            exit_login:"是否退出登录",
            order_no:"订单号",
			order_time:"订单时间",
            cancel:"取消",
            determine:"确定",
            lucky_draw_no:"抽奖号码",
			bullet_screen_message:"弹幕留言",
			leave_comments:"活动留言",
			program_voting:"节目投票",
            program_voting_vote:"vote_ch.png",
			program_voting_voted:"voted_ch.png",
			total_of:"累计",
			votes:"票",
            activity_details:"活动详情",
            send:"发送",
            participant:"参与人",
			participant_must_be_required:"参与人必须填写",
			participant_can_only_be_characters:"参与人必须是2-40长度内",
			please_fill_in_the_name:"请输入参与人姓名",
			please_fill_in_the_phone_no:"请输入参与活动电话号码",
			phone_no_must_be_required:"电话号码必须填写",
            phoneNumberError:"手机号码填写有误",
            pleaseLogInAgain:"请重新登录",
            Failedtogetuserinfor:"获取用户信息失败,请稍后重试",
            beenExhausted:"你的投票机会已经用完",
            remainingVotes:"剩余票数",
            length100:"文本长度不能超过100",
            joinIn:"进入活动",
            networdError:"网络错误，请稍后重试",
            votingIsOver:"投票已经结束",
            votingHasEnded:"该轮投票已结束，请重新进入下轮投票",
	        Live_Photos:"图片直播",
            voting_is_pending:"投票未开始",
            voting_concluded:"投票结束",
        }; 
    }
}
