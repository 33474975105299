import axios from 'axios'
import Config from './Config'
axios.defaults.withCredentials = false;

class MyRequest{
    constructor(){
        this.api = axios.create({
            baseURL:Config.backendUrl,
            withCredentials:false,
            timeout:3000,
        });
    }
    getHeaders(){
        let headers = {
            'Content-Type':'application/json;charset=UTF-8',
        }
        return headers;
    }
    async get(url){
        return await this.api.get(url,{headers:this.getHeaders()});
    }
    async post(url,data){
        return await this.api.post(url,data,{headers:this.getHeaders()});
    }
    async del(url,data = {}){
        return await this.api.delete(url,{params:data},{headers:this.getHeaders()});
    }
}
const myRequest = new MyRequest();
export default myRequest;