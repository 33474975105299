import Config from "./Config";
import Language from "./language/Language";
import DateFormat from "./DateFormat";
DateFormat.addDateFunc();

const language = new Language();

const Common = {
    isLogin:function(){
        var activity_id = this.getCurrentActivityId();
        if(activity_id){
            const userInfo = JSON.parse(this.getLocal(`userInfo_${activityId}`));
            return userInfo != null;
        }
        return false;        
    },
    getUserInfo:function(activityId){
        if(activityId){
            return JSON.parse(this.getLocal(`userInfo_${activityId}`));
        }else{
            var activity_id = this.getCurrentActivityId();
            if(activity_id)
                return JSON.parse(this.getLocal(`userInfo_${activity_id}`));
        }
        return null;
    },
    setUserInfo:function(userInfo,activityId){
        this.setLocal(`userInfo_${activityId}`,JSON.stringify(userInfo));
        this.setCurrentActivityId(activityId);
    },
    getCurrentActivityId:function(){
        return this.getLocal("CurrentActivityId");
    },
    setCurrentActivityId:function(activityId){
        this.setLocal("CurrentActivityId",activityId);
    },
    getWxUserInfo:function(userInfo){
        const wxInfoKey = userInfo.ticket.activity +"-"+ userInfo.id;
        const wxUserInfo = JSON.parse(this.getLocal(wxInfoKey));
        return wxUserInfo;
    },
    setWxUserInfo:function(userInfo,wxInfo){
        var value = {
            avatar_url:wxInfo.data.avatar_url,
            openid: wxInfo.data.openid,
        }
        const wxInfoKey = userInfo.ticket.activity +"-"+ userInfo.id;
        this.setLocal(wxInfoKey,JSON.stringify(value));
    },
    logout:function(activityId){
       // const userInfo = JSON.parse(this.getLocal("userInfo"));
        //const wxInfoKey = userInfo.ticket.activity+"-"+userInfo.id;
        this.removeLocal(`userInfo_${activityId}`);
        this.removeLocal(`userInfo_`);
        //this.removeLocal(wxInfoKey);
    },
    getRes:function(image){
        return Config.resAddr+image;
    },
    getLanguage(key,lang){
        return language.get(key,lang);
    },
    changeLanguage(en,ch,currentLang){
        if(currentLang == "en"){
            return en;
        }else if(currentLang == "ch"){
            return ch;
        }
        return "";
    },
	chechLogin(router){
		if(!this.isLogin()){
			router.push({name: 'Login'});
		}
	},
    getLanguageBtn(){
        if(language.currentLanguage=='ch'){
            return this.getRes("ch.png");
        }else{
            return this.getRes("en.png");
        }
    },
    getCurrentLanguage(){
        /*if(language == null){
            language = new Language();
        }*/
        return language.currentLanguage;
    },
    toggleLanguage(){
        language.toggleLanguage();
    },
    initLanguage(strList){
        for(let key in strList){
            strList[key] = language.get(key);
        }
    },
    setLocal(name,value){
        localStorage.setItem(name,value);
    },
    getLocal(name){
        return localStorage.getItem(name);
    },
    removeLocal(name){
        localStorage.removeItem(name);
    }
}

export default Common;